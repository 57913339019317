import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Button, Col, Container, Spinner } from "react-bootstrap";
import { UserContextData } from "contexts/UserContext";
import HomeNavbar from "components/HomeNavbar/HomeNavbar";

function ChapterViewer() {
  const { name, chapterIndex } = useParams(); // Use chapterIndex instead of chapterNum
  const [chapterData, setChapterData] = useState(null); // State to hold chapter data
  const [loading, setLoading] = useState(false);
  const { currentUser } = UserContextData();
    const navigate = useNavigate(); // Initialize useNavigate hook


  const apiKey = "61254c1e4cmshcc74a38697e3b87p12bb76jsn4854c036d859";

  // Find the index of the chapter using chapterNum

  useEffect(() => {
    // Fetch chapter data using the provided API
    async function fetchChapterData() {
      setLoading(true)
      const options = {
        method: "GET",
        url: `https://mangaverse-api.p.rapidapi.com/manga/image`,
        params: {
          id: `${chapterIndex}`
        },
        headers: {
          "X-RapidAPI-Key": apiKey,
          'x-rapidapi-host': 'mangaverse-api.p.rapidapi.com',
          "Access-Control-Allow-Credentials": "*",
        },
      };

      try {
        const response = await axios.request(options);
        setChapterData(response.data);
        console.log("repsonse:", response, "repsonse.data:", response.data);
        console.log(chapterData);
      } catch (error) {
        console.error(error, chapterData);
        setChapterData(null); // Set chapterData to null if an error occurs
      } finally{
        setLoading(false)
      }
    }

    fetchChapterData(); // Fetch chapter data when component mounts
    console.log(chapterIndex)
  }, [name, chapterIndex]);

  useEffect(() => {
    // Scroll to the top of the content when a new chapter is rendered
    window.scrollTo(0, 0);
  }, [chapterData]);

  const goToPrevChapter = async () => {
    if (chapterData?.previous_chapter) {
      navigate(`/admin/manhwa/${name}/chapter/${chapterData.previous_chapter}`);
    }
  };

  const goToNextChapter = async () => {
    if (chapterData?.next_chapter) {
      navigate(`/admin/manhwa/${name}/chapter/${chapterData.next_chapter}`);
    }
  };

  console.log(chapterData)

  if (!currentUser) {
    return (
      <>
        <HomeNavbar />
        <Container className="mt-5 align-items-center justify-content-center">
          <p>You need to login.</p>
        </Container>
      </>
    );
  }

  // Display chapter content
  if (chapterData) {
    return (
      <div className="content" align="center">
        <Container fluid className="mt-5 mb-5">
          <h2>{chapterData.fullTitle}</h2>
          <div className="p-0 mb-5">
            <Link to={`/admin/manhwa/${name}`}>Back to Chapter List</Link>
            <br />
            <Link to={`/admin/dashboard`}>Back to Dashboard</Link>
          </div>
          <Container className="mt-3">
            {chapterData.previous_chapter != null && (
              <Button variant="secondary" onClick={goToPrevChapter}>
                Previous Chapter
              </Button>
            )}
            {chapterData.next_chapter != null && (
              <Button onClick={goToNextChapter}>Next Chapter</Button>
            )}
          </Container>
          {loading ? (
            <>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </>
          ) : (
            <>
              {chapterData.data ? (
                <>
                  {" "}
                  {chapterData.data.map((imageUrl, index) => (
                    <Col xs="12">
                      <img
                        referrerPolicy="no-referrer"
                        key={index}
                        src={imageUrl.link}
                        alt={`Chapter ${chapterData.chapterIndex}`}
                      />
                    </Col>
                  ))}{" "}
                </>
              ) : (
                <>Error! No Chapter Data...</>
              )}
            </>
          )}

          <Container className="mt-3">
            {chapterData.previous_chapter != null && (
              <Button variant="secondary" onClick={goToPrevChapter}>
                Previous Chapter
              </Button>
            )}
            {chapterData.next_chapter != null && (
              <Button onClick={goToNextChapter}>Next Chapter</Button>
            )}
          </Container>

          <div className="mt-5 mb-5">
            <Link to={`/admin/manhwa/${name}`}>Back to Chapter List</Link>
            <br />
            <Link to={`/admin/dashboard`}>Back to Dashboard</Link>
          </div>
        </Container>
      </div>
    );
  } else {
    return (
      <div className="content" align="center">
        <Container className="mt-5 mb-5">
          <p>Chapter not available yet.</p>
          <Link to={`/admin/manhwa/${name}`}>Back to Chapter List</Link>
          <br />
          <Link to={`/admin/dashboard`}>Back to Dashboard</Link>
        </Container>
      </div>
    );
  }
}

export default ChapterViewer;
