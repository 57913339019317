import React, { useEffect, useState } from "react";

// api context import
import { Link } from "react-router-dom";

// axios import
import axios from "axios";

// react bootstrap components
import { Container, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import { InputGroup, Modal, Input, ModalHeader, Form } from "reactstrap";
import Loading from "components/Loading/Loading";
import ManhwaCard from "components/ManhwaCard/ManhwaCard";
import GenreNavBar from "components/GenreNavBar/GenreNavBar";

export default function Browse() {
  const [webtoonListData, setWebtoonListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedGenre, setSelectedGenre] = useState(); // Added state for selected genre
  const [modalSearch, setmodalSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredWebtoons, setFilteredWebtoons] = useState([]);

  const [count, setCount] = useState(1);
  const [searchPage, setSearchPage] = useState(1); // Page number for search results

  // this function is to open the Search modal
  const toggleModalSearch = () => {
    setmodalSearch(!modalSearch);
  };

  const apiKey = "61254c1e4cmshcc74a38697e3b87p12bb76jsn4854c036d859";

  const genres = [
    "Action",
    "Adventure",
    "Fantasy",
    "Isekai",
    "Shounen",
    "Ecchi",
    "Smut",
  ];


  useEffect(() => {
    pullWebtoonList();
  }, [count]);
  

  {/* 
  useEffect(() => {
    handleSearch();
  }, [searchPage]);
  */}

  const pullWebtoonList = async (e) => {

    // Sets loading on
    setLoading(true);


    // Sets axios requirements
    const options = {
      method: "GET",
      url: "https://mangaverse-api.p.rapidapi.com/manga/latest",
      params: {
        page: {count},
        genres: "Action,Adventure,Fantasy,Isekai,Shounen,Ecchi,Smut",
        nsfw: 'false',
        type: 'all'
      },
      headers: {
        "X-RapidAPI-Key": apiKey,
        "X-RapidAPI-Host": "mangaverse-api.p.rapidapi.com",
        "Access-Control-Allow-Origin": "*",
      },
      
    };


    // Axios call for data
    await axios
      .request(options)
      .then(function (response) {
        console.log(response.data);
        setWebtoonListData(response.data?.data || []); // Adjust according to the API's response structure
        console.log(webtoonListData);
      })
      .catch(function (error) {
        console.error(error);
      });
      

      setTimeout(() => {console.log('3 second break')}, 9000)

    // Sets loading off
    setLoading(false);
  };

  const addCount = () => {
    setCount(count + 1);
  };

  const subCount = () => {
    if (count === 1) {
      return;
    }
    setCount(count - 1);
  };


  {/* Search and genre functionality below */}

  const handleGenreSelection = (genre) => {
    setSelectedGenre(genre);
    if (genre) {
      console.log(genre);
      fetchByGenre(genre);
    } else {
      pullWebtoonList(); // Show all items if no genre is selected
    }
  };

  const handleSearch = async (e = null) => {
    if (e) {
      e.preventDefault();
    }

    if (searchQuery.trim() === "") {
      setFilteredWebtoons([]);
      return;
    }

    const options = {
      method: "GET",
      url: `https://mangaverse-api.p.rapidapi.com/manga/search`,
      params: {
        text: {searchQuery},
        genres: "Action,Adventure,Fantasy,Isekai,Shounen,Ecchi,Smut",
        nsfw: 'false',
        type: 'all'
      },
      headers: {
        "X-RapidAPI-Key": apiKey,
        "X-RapidAPI-Host": "mangaverse-api.p.rapidapi.com'",
      },
    };

    try {
      setLoading(true);
      const response = await axios.request(options);
      setFilteredWebtoons(response.data); // Assuming response.data contains the search results
      console.log(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchByGenre = async (genre) => {
    const options = {
      method: "GET",
      url: `https://manhwaclan-api2.p.rapidapi.com/genre/${genre}`,
      params: { page: 1 },
      headers: {
        "X-RapidAPI-Key": apiKey,
        "X-RapidAPI-Host": "manhwaclan-api2.p.rapidapi.com",
      },
    };

    try {
      setLoading(true);
      const response = await axios.request(options);
      setWebtoonListData(response.data); // Update the list with genre-filtered data
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const clearSearch = () => {
    setFilteredWebtoons([]);
    setSearchQuery("");
    if (selectedGenre) {
      fetchByGenre(selectedGenre);
    } else {
      pullWebtoonList();
    }
  };

  const addSearchPage = () => {
    setSearchPage(searchPage + 1);
  };

  const subSearchPage = () => {
    if (searchPage === 1) return;
    setSearchPage(searchPage - 1);
  };

  return (
    <>
      <div className="content">
        <Container fluid>
          {loading ? (
            <>
              <Loading />
            </>
          ) : (
            <>
              {!webtoonListData || webtoonListData.length === 0 ? (
                <>
                  <p>No data.</p>
                </>
              ) : (
                <>
                  <Container className="mb-3 p-0">
                    <h2 className="mb-2">Browse Our Catalog</h2>
                    <p>
                      Browse through over a hundred popular manga and manhwa
                      series.
                    </p>
                  </Container>
                    
                  <div className="d-flex p-0" onClick={toggleModalSearch}>
                    <p>
                      Search <i className="tim-icons icon-zoom-split" />
                    </p>
                  </div>
                  { /*
                 Render GenreNavBar 
                  <GenreNavBar
                    genres={genres}
                    onSelectGenre={handleGenreSelection}
                  />
                  */}
                  <Row>

                      <>
                      {loading && webtoonListData === [] ? <><p>Loading</p></> : <>
                      {!loading || webtoonListData != [] || webtoonListData.length > 0 ? (
                          webtoonListData.map((manhwa) => (
                              // Rest of your mapping code
                              <Col xs={12} md={6} lg={4} xl={3} key={manhwa.id}>
                                <Link
                                  to={`/admin/manhwa/${
                                    manhwa.id || manhwa.manga_id
                                  }`}
                                >
                                  <ManhwaCard
                                    image={manhwa.cover || manhwa.cover_url ||  manhwa.thumb}
                                    title={manhwa.title || manhwa.name}
                                  />
                                </Link>
                              </Col>
                            ))
                        ) : (
                          <p>No data.</p>
                        )}
                      </>}
                        
                      </>
                      {webtoonListData != null || [] ? <>
                      
                        {webtoonListData.map((manhwa) => (
                              // Rest of your mapping code
                              <Col xs={12} md={6} lg={4} xl={3} key={manhwa.id}>
                                <Link
                                  to={`/admin/manhwa/${
                                    manhwa.id || manhwa.manga_id
                                  }`}
                                >
                                  <ManhwaCard
                                    image={manhwa.cover || manhwa.cover_url ||  manhwa.thumb}
                                    title={manhwa.title || manhwa.name}
                                  />
                                </Link>
                              </Col>
                            ))}</> : <></>}
                      

                  </Row>
                  <ButtonGroup>
                    {filteredWebtoons.length > 0 ? (
                      <>
                        <Button
                          onClick={subSearchPage}
                          disabled={searchPage === 1}
                        >
                          Last
                        </Button>
                        <Button onClick={addSearchPage}>Next</Button>
                      </>
                    ) : (
                      <>
                        <Button onClick={subCount} disabled={count === 1}>
                          Last
                        </Button>
                        <Button onClick={addCount}>Next</Button>
                      </>
                    )}
                  </ButtonGroup>
                  {filteredWebtoons.length > 0 ? (
                    <>
                      <div className="mt-3">
                        <p
                          style={{fontWeight: 'bolder', textDecoration: 'underline'}}
                          onClick={() => {
                            clearSearch();
                          }}
                        >
                          Clear Search Results
                        </p>
                      </div>
                    </>
                  ) : null}
                </>
              )}
            </>
          )}
        </Container>

        <Modal
          modalClassName="modal-search"
          isOpen={modalSearch}
          toggle={toggleModalSearch}
        >
          <ModalHeader>
            <Form onSubmit={handleSearch}>
              <InputGroup>
                <Input
                  placeholder="Search"
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </InputGroup>
            </Form>
            <button
              aria-label="Close"
              className="close"
              onClick={toggleModalSearch}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
          </ModalHeader>
        </Modal>
      </div>
    </>
  );
}
